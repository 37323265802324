<template>
  <v-container fluid class="pa-10">
    <h1>
      Integraciones API de
      <span class="font-weight-light">
        {{ business.shortName }}
      </span>
    </h1>

    <v-snackbar top :timeout="3000" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>

    <div v-if="gettting">
      <!-- <v-skeleton-loader
        class="mx-auto"
        max-width="100%"
        type="card"
      ></v-skeleton-loader>

      <v-skeleton-loader
        class="mx-auto"
        max-width="100%"
        type="card"
      ></v-skeleton-loader> -->
      <div class="ma-2" style="position: relative">
        <div
          style="
            position: absolute;
            z-index: 999;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          "
        >
          <lottie
            :options="defaultOptions"
            :height="150"
            :width="150"
            v-on:animCreated="handleAnimation"
          />
          <p
            :class="
              $vuetify.theme.dark
                ? 'subtitle-1 primary--text'
                : 'subtitle-1 black--text'
            "
          >
            Cargando Data
          </p>
        </div>
        <v-skeleton-loader
          ref="skeleton"
          type="image"
          class="mx-auto d-none d-md-block"
        ></v-skeleton-loader>

        <v-skeleton-loader
          ref="skeleton"
          type="image"
          class="mx-auto d-none d-md-block"
        ></v-skeleton-loader>
        <v-skeleton-loader
          ref="skeleton"
          type="image"
          class="mx-auto d-none d-md-block"
        ></v-skeleton-loader>
      </div>
    </div>

    <div
      v-if="!gettting"
      style="
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 50px;
      "
    >
      <v-row style="max-width: 85%">
        <v-col cols="12" md="12" sm="12">
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <div class="pa-5 mb-10 token-c">
                <p v-if="exist" style="text-align: center; font-size: 18px">
                  Token de autorización
                </p>

                <v-btn
                  v-if="exist"
                  @click="copyToClipboard(api.token)"
                  class="copy"
                  icon
                >
                  <v-icon>fas fa-copy</v-icon></v-btn
                >

                <h3
                  v-if="exist"
                  style="
                    font-size: 20px;
                    color: #FF1744 !important;
                    text-align: center;
                    font-weight: bold;
                    max-width: 80%;
                    overflow-x: hidden;
                  "
                >
                  {{ api.token }}
                </h3>

                <v-btn
                  @click="enableApi"
                  v-else
                  height="55"
                  :loading="enabling"
                  width="80%"
                  color="primary"
                  >Habilitar API</v-btn
                >
              </div>
            </v-col>
          </v-row>

          <v-row v-if="exist" class="token-item">
            <v-col cols="12" sm="12">
              <h2>Actualización automática con proveedores de terceros</h2>
            </v-col>
            <v-col cols="12" sm="5" md="5">
              <v-autocomplete
                v-model="business.thirdPartyProvider"
                :items="thirdPartyProviders"
                label="Seleccione el proveedor de terceros"
                outlined
                item-text="text"
                item-value="value"
                rounded
              />
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <v-text-field
                outlined
                rounded
                :loading="loading"
                label="Ingrese el código de referencia"
                v-model="business.thirdPartyCode"
              />
            </v-col>

            <v-col cols="12" md="1">
              <v-switch
                v-model="business.thirdPartyUpdate"
                :loading="loading"
                label="Activar"
              ></v-switch>
            </v-col>
          </v-row>

          <v-row v-if="exist">
            <v-col cols="12" sm="12" md="12">
              <h2>Webhook</h2>
            </v-col>
            <v-col cols="12" sm="12" md="5">
              <v-text-field
                label="URL"
                rounded
                outlined
                placeholder="Url"
                v-model="api.webhook.url"
                :loading="loading"
              />
            </v-col>

            <v-col cols="12" sm="12" md="3">
              <v-select
                rounded
                readonly
                outlined
                label="Método"
                placeholder="Método"
                :items="methods"
                :loading="loading"
                v-model="api.webhook.method"
              />
            </v-col>

            <v-col cols="12" sm="12" md="3">
              <v-select
                rounded
                outlined
                label="Tipo de contenido"
                :items="types"
                readonly
                :loading="loading"
                v-model="api.webhook.type"
              />
            </v-col>

            <v-col cols="12" sm="12" md="1">
              <v-switch
                :loading="loading"
                v-model="api.webhookActive"
                label="Activo"
              ></v-switch>
            </v-col>
          </v-row>

          <v-row v-if="exist">
            <v-col cols="12" sm="12" md="12">
              <h2>Actualización masiva de productos</h2>
            </v-col>

            <v-col cols="12" sm="12" md="8">
              <v-text-field
                label="ID del comercio"
                rounded
                outlined
                readonly
                v-model="api.businessId"
                :loading="loading"
              />
            </v-col>

            <v-col cols="12" sm="12" md="1">
              <v-switch
                v-model="api.massiveUpdateActive"
                label="Activo"
                :loading="loading"
              ></v-switch>
            </v-col>
          </v-row>

          <v-row v-if="exist" justify="end" class="mt-10">
            <v-col style="display: flex; justify-content: flex-end"
              ><v-btn
                :loading="loading"
                color="primary"
                width="180px"
                @click="save"
                min-height="45px"
                dark
              >
                Guardar
              </v-btn></v-col
            >
          </v-row>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { db, fb } from "@/firebase";
import { mapState } from "vuex";
// import apiC from "@/components/api";
import Lottie from "@/components/Lottie";
import lottieSettings from "@/mixins/lottieMixin";
import ledgerMixin from "@/mixins/ledgerMixin";

export default {
  name: "business-api",
  mixins: [lottieSettings, ledgerMixin],

  components: {
    // apiC,
    lottie: Lottie,
  },
  data() {
    return {
      business: {},
      snackbar: false,
      snackbarText: null,
      exist: false,
      enabling: false,
      gettting: true,
      methods: [{ text: "POST", value: "post" }],
      types: [{ text: "APPLICATION/JSON", value: "application/json" }],
      api: {
        webhook: {},
        token: "",
      },
      loading: false,
      thirdPartyProviders: [
        {
          text: "Farsimán",
          value: "siman",
        },
        {
          text: "Economascotas",
          value: "economascotas",
        },
        {
          text: "Simán Mini Market",
          value: "simanMarket",
        },
        {
          text: "Simán Supermercado",
          value: "simanSuperMarket",
        },
      ],
    };
  },
  watch: {
    selectedBusiness(e) {
      if (e[".key"] != this.business[".key"]) {
        this.business = null;
        this.getBusinessData();
      }
    },
  },
  computed: {
    ...mapState(["selectedBusiness"]),
  },

  methods: {
    enableApi() {
      this.enabling = true;
      var httpApiEnable = fb.functions().httpsCallable("httpApiEnable");

      httpApiEnable({
        businessId: this.selectedBusiness[".key"],
      })
        .then((result) => {
          this.enabling = false;
          this.exist = true;
          this.api = result.data.api;
          this.snackbarText = "API habilitada exitosamente";
        })
        .catch((error) => {
          console.log(error);
          this.enabling = false;
        });
    },

    async save() {
      this.loading = true;
      await db
        .collection("businesses")
        .doc(this.selectedBusiness[".key"])
        .update({
          thirdPartyCode: this.business.thirdPartyCode ?? "",
          thirdPartyProvider: this.business.thirdPartyProvider ?? "",
          thirdPartyUpdate: this.business.thirdPartyUpdate ?? false,
        });

      await db
        .collection("apiKeys")
        .doc(this.api.id)
        .update({
          webhookActive: this.api.webhookActive ?? false,
          webhook: {
            type: this.api.webhook.type ?? "",
            method: this.api.webhook.method ?? "",
            url: this.api.webhook.url ?? "",
          },

          massiveUpdateActive: this.api.massiveUpdateActive ?? false,
        });
      this.loading = false;
      this.snackbar = true;
      this.snackbarText = "Información actualizada correctamente.";
    },
    copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(
        () => {
          this.snackbar = true;
          this.snackbarText = "Copiado al portapapeles";
        },
        (err) => {
          console.error("Async: Could not copy text: ", err);
        }
      );
    },
    async getBusinessData() {
      if (!this.selectedBusiness) {
        this.$router.push({ path: "/" });
      }

      let business = await db
        .collection("businesses")
        .doc(this.selectedBusiness[".key"])
        .get();

      business = business.data();
      business[".key"] = this.selectedBusiness[".key"];

      this.business = JSON.parse(JSON.stringify(business));
      this.businessCopy = JSON.parse(JSON.stringify(business));

      this.daySelected = "monday";
    },
  },
  destroyed() {
    this.$store.commit("setSearchTerm", "");
    this.$store.commit("setSearch", "");
  },
  mounted() {
    this.$store.state.visibleSearch = false;
    this.getBusinessData();

    var httpApiGet = fb.functions().httpsCallable("httpApiGet");

    httpApiGet({
      businessId: this.selectedBusiness[".key"],
    })
      .then((response) => {
        this.gettting = false;
        if (response.data.code == "001") {
          this.exist = false;
        } else {
          this.exist = true;

          this.api = response.data.api;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>

<style scoped lang="scss">
.token-c {
  border-radius: 10px;
  border: 1px solid gray;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  position: relative;

  .copy {
    position: absolute;
    right: 15px;
    bottom: 15px;

    color: #FF1744;
    font-weight: bold;

    font-size: 20px !important;

    cursor: pointer;
  }
}
</style>


